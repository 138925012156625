import { TestService } from '../../../../core/test-service/test.service';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { Test } from 'src/app/core/interfaces/test';
import { MatIcon } from '@angular/material/icon';
import { TranslocoModule } from '@jsverse/transloco';
import { upperCaseFromUnderscore } from '../../../../core/util/string.util';

@Component({
  selector: 'app-test-share-info-dialog',
  templateUrl: './test-share-info-dialog.component.html',
  styleUrls: ['./test-share-info-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogTitle, MatIcon, MatDialogClose, MatDialogContent, TranslocoModule, MatDialogActions],
})
export class TestShareInfoDialogComponent implements OnInit {
  user_name = '';
  duration = 0;
  questions_count = 0;
  points = 0;
  categories = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { test: Test },
    private testService: TestService
  ) {}

  ngOnInit(): void {
    this.user_name = this.data.test.user_name;
    this.duration = this.testService.getDuration(this.data.test);
    this.questions_count = this.testService.getQuestionsCount(this.data.test);
    this.points = this.data.test.points;

    const categories = this.testService.getCategories(this.data.test);
    const cat1 = Array.from(new Set(categories.map(c => c.split('.')[0])));

    this.categories = cat1.map(c => upperCaseFromUnderscore(c)).join(', ');
  }
}
