import { Categorie } from './categorie';

export enum UserTestDate {
  Soon,
  Fixed,
  NotKnow,
}

export interface UserPlatform {
  user_type: '';
  max_score: number;
  avg_score: number;
  level: number;
  community_points: number;
  rank_index?: number;
  best_category: string;
  categories: Array<Categorie>;
  simulation_done: number;
  version: string;
  site: string;
  evaluation: { [key: string]: number };
  expected_points: number;
  test_date: number;
  test_date_type: UserTestDate;
  weekly_hours: number;
  selected_profile?: string;
  first_login: boolean;
}
