import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DIALOG_INFO_DEFAULT_TITLE } from '../../constant';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'app-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  standalone: true,
  imports: [TranslocoModule],
})
export class InfoDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialgoRef: MatDialogRef<InfoDialogComponent>
  ) {
    if (this.data == null) {
      this.data = {};
    }
    if (this.data.title == null) this.data.title = DIALOG_INFO_DEFAULT_TITLE;
    if (this.data.button_title == null) this.data.button_title = 'dialogs.info.close';
  }

  close() {
    this.dialgoRef.close();
  }
}
