import { Component } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { ConfigService } from '../../core/config/config.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent {
  options: AnimationOptions = {
    path: '/assets/lotties/spinner_lottie_doctor.json',
  };

  constructor(configService: ConfigService) {
    configService.getUiConfig('_spinner_url').subscribe(value => (this.options = { path: value || '/assets/lotties/spinner_lottie_doctor.json' }));
  }
}
