<div class="d-flex justify-content-between align-items-center" mat-dialog-title>
  <h1 class="m-0">{{ 'sidenav.device_list' | transloco }}</h1>
  <mat-icon fontSet="material-symbols-outlined" class="pointer" mat-dialog-close>close</mat-icon>
</div>

<div class="py-3" mat-dialog-content>
  <div class="d-flex flex-column">
    @for (device of devices; track device) {
      <div class="w-100 border-bottom rounded d-flex justify-content-between align-items-center flex-row py-3">
        <div class="d-flex justify-content-start align-items-center">
          @switch (true) {
            @case (device.device_type === 'desktop') {
              <mat-icon fontSet="material-symbols-outlined" class="mr-3">computer</mat-icon>
            }
            @case (device.device_type === 'tablet') {
              <mat-icon fontSet="material-symbols-outlined" class="mr-3">tablet_android</mat-icon>
            }
            @case (device.device_type === 'smartphone') {
              <mat-icon fontSet="material-symbols-outlined" class="mr-3">smartphone</mat-icon>
            }
            @default {
              <mat-icon fontSet="material-symbols-outlined" class="mr-3">devices</mat-icon>
            }
          }
          <div class="d-flex flex-column">
            <span class="font-weight-bold">{{ device.device_model || device.os }}</span>
            <span>{{ device.browser }}</span>
          </div>
        </div>
        @if (current_device && device.uuid == current_device.uuid) {
          <mat-icon fontSet="material-symbols-outlined" class="pointer text-success">check_circle</mat-icon>
        }
        @if (current_device && device.uuid != current_device.uuid) {
          <div class="d-flex flex-column align-items-end">
            <span>{{ 'utils.last_login' | transloco }}</span>
            <span class="font-weight-bold">{{ device.last_update | date: 'short' }}</span>
          </div>
        }
      </div>
    }
  </div>
</div>
