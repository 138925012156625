import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MigrationService } from 'src/app/core/migration-service/migration.service';
import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { BaseDialogIconComponent } from '@futura/futura-ui/dialog';

@Component({
  selector: 'app-migration-dialog',
  templateUrl: './migration-dialog.component.html',
  styleUrls: ['./migration-dialog.component.scss'],
  standalone: true,
  imports: [BaseDialogIconComponent],
})
export class MigrationDialogComponent implements OnInit, OnDestroy {
  public readonly messages = [
    'Attendi qualche istante mentre Futura si aggiorna...',
    "Costruzione di un ponte verso l'eccellenza accademica...",
    'Caricamento della saggezza degli antichi...',
    'Calibrazione della matrice di apprendimento quantistica...',
    'Lucidatura della lavagna virtuale...',
    'Traduzione dei libri di testo in sequenze binarie...',
    'Bilanciamento delle equazioni del progresso educativo...',
    "Simulazione dell'ambiente di classe perfetto...",
    "Quantificazione del valore di 'x'... ",
    "Canalizzazione dell'energia delle menti coinvolte...",
  ];

  public message: string = this.messages[0];
  private showedMessages: string[] = [];
  private messageIntervalId?: ReturnType<typeof setTimeout>;
  private migrationStatusIntervalId?: ReturnType<typeof setTimeout>;

  private migration_uuid?: string;

  constructor(
    private migrationService: MigrationService,
    private utils: UtilsService,
    private dialogRef: MatDialogRef<MigrationDialogComponent>
  ) {}

  ngOnInit(): void {
    this.messageIntervalId = setInterval(() => {
      if (this.showedMessages.length === this.messages.length) this.showedMessages = [];
      const availableMessages = this.messages.filter(m => !this.showedMessages.includes(m));
      const message = availableMessages[Math.floor(Math.random() * availableMessages.length)];
      this.showedMessages.push(message);
      this.message = message;
    }, 5000);

    this.startMigration();
  }

  ngOnDestroy(): void {
    if (this.messageIntervalId) clearInterval(this.messageIntervalId);
    if (this.migrationStatusIntervalId) clearInterval(this.migrationStatusIntervalId);
  }

  startMigration(): void {
    this.migrationService.startMigration().subscribe(({ migration_uuid }) => {
      this.migration_uuid = migration_uuid;
      this.statusMigration();
    });
  }

  statusMigration(): void {
    if (!this.migration_uuid) return;
    this.migrationService.migrationStatus(this.migration_uuid).subscribe({
      next: migration => {
        if (migration.content.status === 'completed') this.endMigration(true);
        else this.migrationStatusIntervalId = setTimeout(() => this.statusMigration(), 2000);
      },
      error: () => {
        // this.utils.show500();
        this.endMigration(true);
      },
    });
  }

  endMigration(success: boolean): void {
    this.dialogRef.close(success);
    this.ngOnDestroy();
  }
}
