import { Component, HostListener, input, signal } from '@angular/core';
import { UserpilotService } from '../../../../core/userpilot/userpilot.service';
import { Router } from '@angular/router';
import { UserService } from '../../../../core/user-service/user.service';
import { AliceUiService } from '../../../alice-v2/service/alice-ui/alice-ui.service';
import { UtilsService } from '../../../../core/utils-service/utils.service';
import { UsersnapService } from '../../../../core/usersnap/usersnap.service';
import { BREAKPOINT_VALUES } from '@futura/futura-ui/breakpoint';

@Component({
  selector: 'app-help-section',
  templateUrl: './help-section.component.html',
  styleUrls: ['./help-section.component.scss'],
})
export class HelpSectionComponent {
  public collapsed = input<boolean>(false);
  public isMobileDevice = signal<boolean>(false);

  constructor(
    private userpilotService: UserpilotService,
    private userService: UserService,
    private aliceUiService: AliceUiService,
    private utilService: UtilsService,
    private usersnapService: UsersnapService,
    private router: Router
  ) {
    this.isMobile();
  }

  public restartTutorial(): void {
    this.router
      .navigate(['simulations'])
      .then(() => this.userpilotService.userpilot.trigger('a3n3slBQl0'))
      .catch(console.error);
  }

  public openGeneralSupport(): void {
    this.userService.openSupportDialog();
  }

  public openAlice(): void {
    const chatButton = this.aliceUiService.open();
    chatButton.afterOpened().subscribe(() => this.utilService.sendEvent('action_gpt_v2_chat_start'));
  }

  public reportIssue(): void {
    this.usersnapService.openWidget('2f396744-82e8-4437-8556-4eb58001507c'); // project key bug_report event
  }

  @HostListener('window:resize')
  private isMobile(): void {
    this.isMobileDevice.set(window.innerWidth <= BREAKPOINT_VALUES.md);
  }
}
