import { TestShareInfoDialogComponent } from '../../../shared/dialogs/info-dialogs/test-share-info-dialog/test-share-info-dialog.component';
import { TestService } from '../../../core/test-service/test.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { UserService } from 'src/app/core/user-service/user.service';
import { InfoDialogComponent } from 'src/app/shared/dialogs/info-dialog/info-dialog.component';
import { finalize } from 'rxjs/operators';
import { UtilsService } from '../../../core/utils-service/utils.service';

@Component({
  selector: 'app-simulation-shorter',
  template: '<div class="fut-background"></div>',
})
export class SimulationShorterComponent implements OnInit {
  token: string = this.route.snapshot.paramMap.get('token') as string;

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private testService: TestService,
    private utilsService: UtilsService
  ) {}

  confirmChallenge(): void {
    // this.confirmChallenge();
    this.uiService.showSpinner = true;
    this.testService
      .getSharedInfo(this.token)
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: info => {
          this.dialog
            .open(TestShareInfoDialogComponent, { data: { test: info.test } })
            .afterClosed()
            .subscribe(accept => {
              if (accept) this.startFromToken(this.token);
              else this.utilsService.gotoHomePage().catch(console.error);
            });
        },
        error: err => {
          console.log(err);
          this.uiService.errorToastGeneric();
          this.utilsService.gotoHomePage().catch(console.error);
        },
      });
  }

  ngOnInit(): void {
    this.uiService.showSpinner = true;
    this.userService.getFuturaUser().subscribe({
      next: () => {
        this.confirmChallenge();
      },
      error: () => {
        console.log('Here');
        this.dialog.open(InfoDialogComponent, {
          data: {
            title: 'Sei stato sfidato!',
            content: 'Prima di metterti alla prova è necessario registrarsi!',
            button_title: 'Registrati',
          },
        });
        this.router.navigate(['login']);
        localStorage.setItem('try_to_navigate', location.pathname);
        this.uiService.showSpinner = false;
      },
    });
  }

  startFromToken(token: string) {
    this.uiService.showSpinner = true;
    this.testService
      .getSharedTest(token)
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: test => this.router.navigate(['test', test.instance_id]),
        error: err => {
          console.log(err);
          this.uiService.errorToastGeneric();
          this.utilsService.gotoHomePage();
        },
      });
  }
}
