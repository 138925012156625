import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { MessageV2 } from 'src/app/core/interfaces/chat';
import { ProductsService } from 'src/app/core/products-service/products.service';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AliceV2Service {
  constructor(
    private http: HttpClient,
    private productService: ProductsService
  ) {}

  public chatStart(): Observable<MessageV2> {
    const url = environment.alice_api + '/conversation/';
    return this.productService.getAffiliatedAdmins().pipe(
      switchMap(admins =>
        this.http.post<MessageV2>(url, {
          type: 'start',
          tutors: admins.tutors,
          customer_cares: admins.customer_cares,
        })
      )
    );
  }

  public chatReplay(thread_id: string, message: string): Observable<MessageV2> {
    const url = environment.alice_api + '/conversation/';
    return this.http.post<MessageV2>(url, {
      thread_id,
      message,
      type: 'message',
    });
  }

  public chatEvaluate(thread_id: string, evaluation?: number, evaluation_message?: string): Observable<MessageV2> {
    const url = environment.alice_api + '/conversation/evaluate';
    return this.http.post<MessageV2>(url, {
      thread_id,
      evaluation,
      evaluation_message,
    });
  }
}
