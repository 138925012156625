import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule } from '@jsverse/transloco';
import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeRoutingModule } from './home-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { BaseComponent } from './base.component';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';
import { AliceV2Module } from 'src/app/shared/alice-v2/alice-v2.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimulationShorterComponent } from './simulation-shorter/simulation-shorter.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { ToastrModule } from 'ngx-toastr';
import { CouponRouteComponent } from './coupon-route/coupon-route.component';
import { NgxMaskModule } from 'ngx-mask';
import { ScalapayComponent } from './scalapay/scalapay.component';
import { MatIconModule } from '@angular/material/icon';
import { MatSliderModule } from '@angular/material/slider';
import { SidenavModule } from '../../shared/sidenav/sidenav.module';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AliceChatComponent } from '../../shared/components/alice-chat/alice-chat.component';

@NgModule({
  declarations: [BaseComponent, SimulationShorterComponent, CouponRouteComponent, ScalapayComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    BrowserModule,
    MatDialogModule,
    ReactiveFormsModule,
    SwiperModule,
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AliceV2Module,
    ToastrModule.forRoot(),
    NgxMaskModule.forChild(),
    TranslocoModule,
    MatIconModule,
    MatSliderModule,
    SidenavModule,
    TypeaheadModule,
    AliceChatComponent,
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: localStorage.getItem('language') || environment.locale,
    },
    { provide: MAT_DATE_LOCALE, useValue: localStorage.getItem('language') || environment.locale },
    // {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    // {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  schemas: [],
  exports: [],
})
export class HomeModule {}
