import { ToastrService } from 'ngx-toastr';
import { ProductsService } from '../../products-service/products.service';
import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { finalize } from 'rxjs/operators';

export const loggedActivated: CanActivateFn = () => {
  const router = inject(Router);
  const productService = inject(ProductsService);
  const toast = inject(ToastrService);

  const result: boolean = localStorage.getItem('is_logged') != null;
  if (result) {
    const coupon_id = localStorage.getItem('try_coupon');
    if (coupon_id) {
      productService
        .activateCoupon(coupon_id)
        .pipe(finalize(() => localStorage.removeItem('try_coupon')))
        .subscribe(coupon => {
          toast.success(`Coupon ${coupon.content.name} attivato con successo!`, '', {
            progressBar: true,
            closeButton: true,
            positionClass: 'toast-bottom-right',
            timeOut: 3000,
          });
          localStorage.setItem('coupon', coupon_id);
        });
    }
    return true;
  } else {
    // console.log('Redirect');
    // Se nel Localstorage non c'è la variabile allora restituisci Falso e riporta l'utente sul login
    if (location.pathname != '/' && !location.pathname.startsWith('/p/') && location.pathname != 'logout')
      localStorage.setItem('try_to_navigate', location.pathname);
    router.navigate(['login']).catch(console.error);
    return false;
  }
};

export const loggedChildActivated: CanActivateChildFn = () => {
  const router = inject(Router);

  const result: boolean = localStorage.getItem('is_logged') != null;
  if (result) {
    return true;
  } else {
    // Se nel Localstorage non c'è la variabile allora restituisci Falso e riporta l'utente sul login
    if (location.pathname != '/') localStorage.setItem('try_to_navigate', location.pathname);
    router.navigate(['register']);
    return false;
  }
};
