import { AfterContentChecked, Directive, effect, ElementRef, HostListener, input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appTextScrolling]',
  standalone: true,
})
export class TextScrollingDirective implements AfterContentChecked {
  public contentText = input<string>('');
  public hoverPerimeterTextScrolling = input<HTMLElement>();
  private spanChild: HTMLSpanElement;
  private readonly keepDecimals = 2;
  private readonly timeStep = 0.07;
  private oldContent = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.renderer.addClass(this.el.nativeElement, 'text-scrolling-wrapper');
    this.spanChild = this.renderer.createElement('span');
    const newContentText = this.renderer.createText(this.contentText());
    const newContentTextDOM = this.renderer.createText(this.el.nativeElement.textContent);

    this.removeTextNode();

    this.renderer.appendChild(this.spanChild, newContentText);
    this.renderer.appendChild(this.spanChild, newContentTextDOM);
    this.renderer.appendChild(this.el.nativeElement, this.spanChild);

    effect(() => {
      if (this.contentText()) {
        this.renderer.setProperty(this.spanChild, 'textContent', this.contentText());
      } else {
        this.renderer.setProperty(this.spanChild, 'textContent', this.el.nativeElement.textContent);
      }
      this.removeTextNode();
    });

    effect(() => {
      if (this.hoverPerimeterTextScrolling()) {
        this.renderer.addClass(this.hoverPerimeterTextScrolling(), 'text-scrolling-hover-perimeter');
        this.renderer.listen(this.hoverPerimeterTextScrolling(), 'mouseenter', () => {
          this.onMouseEnter();
        });
      }
    });
  }

  ngAfterContentChecked(): void {
    const text = this.el.nativeElement.textContent;
    if (this.oldContent === text) {
      return;
    }
    if (text && !this.contentText()) {
      this.oldContent = text;
      this.renderer.setProperty(this.spanChild, 'textContent', text);
      this.removeTextNode();
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    const titleTextOverflow = this.spanChild.offsetWidth > this.el.nativeElement.offsetWidth + 2;
    if (titleTextOverflow) {
      const calculatedTranslate = ((this.spanChild.offsetWidth - this.el.nativeElement.offsetWidth) / this.spanChild.offsetWidth) * 100;
      const translatePercX = calculatedTranslate ? `-${calculatedTranslate.toFixed(this.keepDecimals)}%` : '0%';
      const calculatedDuration = calculatedTranslate * this.timeStep;
      const durationSec = `${calculatedDuration.toFixed(this.keepDecimals)}s`;
      this.spanChild.style.setProperty('--text-scrolling-100perc-translateX', translatePercX);
      this.spanChild.style.setProperty('--text-scrolling-duration', durationSec);
      this.renderer.addClass(this.spanChild, 'text-scrolling-animation-active');
    } else {
      this.renderer.removeClass(this.spanChild, 'text-scrolling-animation-active');
    }
  }

  private removeTextNode(): void {
    const childNodes: ChildNode[] = Array.from(this.el.nativeElement.childNodes);
    childNodes.forEach(node => {
      if (node.nodeType === Node.TEXT_NODE) {
        this.renderer.removeChild(this.el.nativeElement, node);
      }
    });
  }
}
