import { Component, computed, input, NgZone, OnDestroy, OnInit, signal } from '@angular/core';
import { SidenavElement } from '../../model/sidenav.model';
import { AnimationOptions } from 'ngx-lottie';
import { Subscription } from 'rxjs';
import { UiService } from '../../../../core/ui-service/ui.service';
import { AnimationItem } from 'lottie-web';

@Component({
  selector: 'app-sidenav-element',
  templateUrl: './sidenav-element.component.html',
  styleUrls: ['./sidenav-element.component.scss'],
})
export class SidenavElementComponent implements OnInit, OnDestroy {
  public element = input.required<SidenavElement>();
  public collapsed = input<boolean>(false);
  public activeElement = input<string | undefined>('');
  public isLive = signal<boolean>(false);
  public animatedLottieOptions = computed(() => this.getAnimatedLottieOptions());
  public isActive = computed(() => this.activeElement() === this.element()?.routerLink);
  private animationItem: AnimationItem | null = null;
  private subscriptions = new Subscription();

  constructor(
    private uiService: UiService,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.uiService._onLiveStatusChange.subscribe(newValue => this.isLive.set(newValue)));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private getAnimatedLottieOptions(): AnimationOptions {
    let animatedLottieOptions: AnimationOptions | undefined;
    if (this.isActive()) {
      animatedLottieOptions = this.element().lottieActive;
    } else {
      animatedLottieOptions = this.element().lottie;
    }
    animatedLottieOptions = { ...animatedLottieOptions };
    animatedLottieOptions.autoplay = false;
    return animatedLottieOptions;
  }

  public animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }

  public startAnimationOnHover(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.animationItem) {
        this.animationItem.play();
      }
    });
  }

  public stopAnimationOnHover(): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.animationItem) {
        this.animationItem.stop();
      }
    });
  }
}
