import { TranslocoService } from '@jsverse/transloco';
import { AuthService } from './core/auth-service/auth.service';
import { UserService } from './core/user-service/user.service';
import { getDatabase, onValue, ref } from '@angular/fire/database';
import { FirebaseApp } from '@angular/fire/app';
import { DomSanitizer, Meta } from '@angular/platform-browser';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import localeEn from '@angular/common/locales/en';
import { Userpilot } from 'userpilot';
import { LANGUAGES } from './shared/constant';
import { loadSpace } from '@usersnap/browser';
import { gEnvironment } from 'src/environments/global_environment';
import { captureMessage } from '@sentry/angular-ivy';
import { ConfigService } from './core/config/config.service';
import { SwUpdate } from '@angular/service-worker';

if ((localStorage.getItem('language') || environment.locale) == 'it') registerLocaleData(localeIt);
else if ((localStorage.getItem('language') || environment.locale) == 'en') registerLocaleData(localeEn);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'futura';

  maintance_mode_url = this.sanitizer.bypassSecurityTrustResourceUrl(environment.maintance_mode_url);
  maintance_mode = false;

  constructor(
    private sanitizer: DomSanitizer,
    private fApp: FirebaseApp,
    private userService: UserService,
    private translate: TranslocoService,
    private authService: AuthService,
    private meta: Meta,
    private swUpdate: SwUpdate,
    configService: ConfigService
  ) {
    this.checkVersion();
    setInterval(() => this.checkVersion(), 3 * 60 * 60 * 1000);

    // (window as any).be_url = environment.be_url;
    localStorage.setItem('be_url', environment.be_url);
    localStorage.setItem('platform', environment.platform);
    this.translate.setDefaultLang(environment.locale);
    this.translate.setAvailableLangs(LANGUAGES.map(l => l.value));
    this.translate.setActiveLang(environment.locale);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const newUrl = new URL(window.location.href);

    loadSpace(gEnvironment.usersnap_key).then(api => {
      api.init();
    });

    urlParams.forEach((value, key) => {
      if (key.startsWith('utm')) {
        localStorage.setItem(key, value);
        newUrl.searchParams.delete(key);
      }
    });

    if ((urlParams as any).size > 0) window.history.replaceState({}, document.title, newUrl.href);
    configService.getUiConfig('_bg_image').subscribe(img => {
      if (img) document.documentElement.style.setProperty('--bg-image', `url("${img}")`);
    });

    configService.getUiConfig('_metatags').subscribe(metatags => {
      if (metatags) {
        metatags.forEach(metatag => {
          this.meta.addTag({ property: metatag.key, content: metatag.value });
          console.log({ property: metatag.key, content: metatag.value });
        });
      }
    });

    if (environment.production) {
      if (location.protocol === 'http:' && location.href.includes('futura.study')) {
        window.location.href = location.href.replace('http', 'https');
      }
      // console.log = () => {};
      const scriptTagManagerHead = document.createElement('script');
      scriptTagManagerHead.async = true;
      scriptTagManagerHead.innerHTML = `
    (function (w, d, s, l, i) {
        w[l] = w[l] || []; w[l].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        }); var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
            'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-MJ22W8Q');
    `;
      document.head.append(scriptTagManagerHead);
    }
  }

  ngOnInit(): void {
    Userpilot.initialize(environment.userpilot_token);
  }

  ngAfterViewInit(): void {
    const rDb = getDatabase(this.fApp);
    console.log('heere');
    const path = ref(rDb, 'maintance_mode');
    onValue(path, snap => {
      const val = snap.val();
      console.log({ val });
      if (val) {
        this.userService.getFuturaUser().subscribe(() => {
          this.authService.logout().subscribe();
          captureMessage('App in maintance mode');
        });
      }
      this.maintance_mode = val;
    });
  }

  private checkVersion(): void {
    /*
      The service worker checks if there's a new version of the app available and in that case
      it downloads it and it's ready to activate it. The activation will be automatic after a reload of the page or
      a close and reopen of the website. Avoids to force the reload because it will be awful.
      Should work consistently on every the browser and device.
      The check is repeated at open and every 3hrs.
     */
    this.swUpdate
      .checkForUpdate()
      .then(newVersion => {
        if (newVersion) {
          console.log('New version available');
        } else {
          console.log('No new version available');
        }
      })
      .catch(console.error);
  }
}
