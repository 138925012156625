import { HttpInterceptorFn } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { inject } from '@angular/core';
import { Auth, idToken } from '@angular/fire/auth';
import { switchMap, take } from 'rxjs';

export const futuraInterceptor: HttpInterceptorFn = (req, next) => {
  const auth = inject(Auth);
  const idToken$ = idToken(auth);

  return idToken$.pipe(
    take(1),
    switchMap(token => {
      const additionalHeaders: Record<string, string | Array<string>> = {};
      additionalHeaders['Locale'] = localStorage.getItem('language') || environment.locale;

      const invitor = localStorage.getItem('invitor');
      if (invitor) {
        additionalHeaders['Invitor'] = invitor;
      }
      const invitorType = localStorage.getItem('invitor_type');
      if (invitorType) {
        additionalHeaders['Invitor-Type'] = invitorType;
      }

      if (token) {
        additionalHeaders['Authorization'] = token;
      }

      if (!req.headers.get('Target-User')) {
        additionalHeaders['Target-User'] = 'self';
      }

      const futReq = req.clone({
        setHeaders: additionalHeaders,
      });
      return next(futReq);
    })
  );
};
