import { AuthV2Component } from './auth-v2/auth-v2.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLogoutComponent } from './auth-logout/auth-logout.component';

const routes: Routes = [
  // {path: "login", component: AuthLoginComponent},
  // {path: "register", component: AuthRegisterComponent},
  { path: 'logout', component: AuthLogoutComponent },
  { path: 'login', component: AuthV2Component },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AuthRoutingModule {}
