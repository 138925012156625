import { ApplicationRef, ComponentRef, createComponent, Injectable, Injector } from '@angular/core';
import { SpinnerComponent } from './spinner.component';
import { UiService } from '../../core/ui-service/ui.service';

@Injectable({
  providedIn: 'root',
})
export class SpinnerInitializerService {
  private componentRef?: ComponentRef<SpinnerComponent>;
  private appRef?: ApplicationRef;

  constructor(private injector: Injector) {}

  private get applicationRef(): ApplicationRef {
    if (!this.appRef) {
      this.appRef = this.injector.get(ApplicationRef);
    }
    return this.appRef;
  }

  public init(): void {
    this.injector.get(UiService)._onShowSpinner.subscribe(value => {
      if (value) {
        this.addSpinner();
      } else {
        this.removeSpinner();
      }
    });
  }

  private addSpinner(): void {
    this.removeSpinner();
    this.componentRef = createComponent(SpinnerComponent, {
      environmentInjector: this.applicationRef.injector,
    });
    this.applicationRef.attachView(this.componentRef.hostView);
    document.body.appendChild(this.componentRef.location.nativeElement);
  }

  private removeSpinner() {
    if (!this.componentRef) {
      return;
    }
    this.applicationRef.detachView(this.componentRef.hostView);
    this.componentRef.destroy();
    this.componentRef = undefined;
  }
}
