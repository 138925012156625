import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  input,
  OnDestroy,
  OnInit,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { User } from '../../../../core/interfaces/user';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../core/user-service/user.service';
import { ViewDevicesListDialogComponent } from '../../../dialogs/view-devices-list-dialog/view-devices-list-dialog.component';
import { UtilsService } from '../../../../core/utils-service/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { UiService } from '../../../../core/ui-service/ui.service';
import { StreakInfo } from '../../../streak/model/streak.model';
import { StreakService } from '../../../streak/service/streak.service';
import { switchMap } from 'rxjs/operators';
import { USER_DEFAULT_IMAGE } from '../../../../core/config/config.model';
import { COMPONENT_STATE, ComponentState } from '@futura/futura-ui/ghost';

@Component({
  selector: 'app-profile-section',
  templateUrl: './profile-section.component.html',
  styleUrls: ['./profile-section.component.scss'],
})
export class ProfileSectionComponent implements OnInit, OnDestroy, AfterContentChecked {
  public userImage = signal(USER_DEFAULT_IMAGE);
  public user = signal<User | undefined>(undefined);
  public showProfileRoute = input(true);
  public collapsed = input(false);
  public activeElement = input<string | undefined>('');
  public readonly routerLink = 'profile';
  public streakInfo?: StreakInfo;
  public currentStreakCount = signal(0);
  public readonly streakIcon = signal('/assets/streak/streak_blue.svg');
  public stateStrike = signal<ComponentState>(COMPONENT_STATE.LOADING);
  public stateUser = signal<ComponentState>(COMPONENT_STATE.LOADING);
  public titleTextOverflow = false;
  private subscriptions = new Subscription();
  public closeSidenav = output();
  @ViewChild('titleWrapper', { read: ElementRef, static: false }) private titleWrapper?: ElementRef;
  @ViewChild('titleText', { read: ElementRef, static: false }) private titleText?: ElementRef;

  constructor(
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private uiService: UiService,
    private utilsService: UtilsService,
    private dialog: MatDialog,
    private streakService: StreakService
  ) {}

  public ngOnInit(): void {
    this.subscriptions.add(
      this.userService.onUserLoaded.subscribe(user => {
        this.user.set(user);
        this.stateUser.set(COMPONENT_STATE.NONE);
      })
    );
    this.subscriptions.add(this.uiService.onUserImageUpdated.subscribe(newImage => this.userImage.set(newImage)));
    this.subscriptions.add(
      this.streakService.get().subscribe(streak => {
        this.streakInfo = streak;
        this.loadStreak(streak);
      })
    );
    this.subscriptions.add(
      this.streakService.triggerReloadSubject.pipe(switchMap(() => this.streakService.get())).subscribe(streak => {
        this.streakInfo = streak;
        this.loadStreak(streak);
      })
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public ngAfterContentChecked(): void {
    this.computeTextOverflow();
  }

  public openDeviceList(): void {
    this.closeSidenav.emit();
    this.utilsService.sendEvent('pv_profile_devices');
    this.dialog.open(ViewDevicesListDialogComponent, { maxWidth: '90vw' });
  }

  public openStreak(): void {
    this.streakService.openDialog(this.streakInfo);
  }

  @HostListener('window:resize')
  private computeTextOverflow(): void {
    if (!this.titleText || !this.titleWrapper) {
      return;
    }
    this.titleTextOverflow = this.titleText.nativeElement.offsetWidth > this.titleWrapper.nativeElement.offsetWidth + 2;
    this.ref.detectChanges();
  }

  private loadStreak(streakInfo: StreakInfo): void {
    this.stateStrike.set(COMPONENT_STATE.NONE);

    const counts = this.streakService.loadStreakInfo(streakInfo);

    this.currentStreakCount.set(counts.currentStreakCount);
  }

  public missingImg(): void {
    if (!this.user()) {
      return;
    }
    const userFullName = this.user()?.content.nickname.toUpperCase().split('_').join('+');
    this.userImage.set(`https://ui-avatars.com/api/?background=152148&font-size=.6&color=fff&name=${userFullName}&bold=True&size=24`);
  }
}
