import { TranslocoModule } from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AuthLogoutComponent } from './auth-logout/auth-logout.component';
import { AuthV2Component } from './auth-v2/auth-v2.component';
import { MatIconModule } from '@angular/material/icon';
import { EditableDirective } from '@futura/futura-ui/editable';
import { PhoneInputComponent } from '@futura/futura-ui/phone-input';

@NgModule({
  declarations: [AuthLogoutComponent, AuthV2Component],
  imports: [CommonModule, AuthRoutingModule, ReactiveFormsModule, TranslocoModule, MatIconModule, PhoneInputComponent, EditableDirective],
})
export class AuthModule {}
