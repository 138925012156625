import { UserDevice } from '../../../core/interfaces/user';
import { MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { TranslocoModule } from '@jsverse/transloco';
import { UserService } from 'src/app/core/user-service/user.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/core/interfaces/user';
import { forkJoin } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MatIcon } from '@angular/material/icon';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-view-devices-list-dialog',
  templateUrl: './view-devices-list-dialog.component.html',
  styleUrls: ['./view-devices-list-dialog.component.scss'],
  standalone: true,
  imports: [MatIcon, MatDialogClose, TranslocoModule, MatDialogTitle, MatDialogContent, DatePipe],
})
export class ViewDevicesListDialogComponent implements OnInit {
  user?: User;

  devices = new Array<UserDevice>();

  current_device?: UserDevice;

  constructor(
    private uiService: UiService,
    private userService: UserService,
    private translate: TranslocoService,
    private dialogRef: MatDialogRef<ViewDevicesListDialogComponent>
  ) {}

  ngOnInit(): void {
    this.uiService.showSpinner = true;
    forkJoin([this.userService.getFuturaUser(), this.userService.getUserDeviceInfo()])
      .pipe(finalize(() => (this.uiService.showSpinner = false)))
      .subscribe({
        next: ([user, current_device]) => {
          this.user = user;
          this.current_device = current_device;
          this.devices = this.user.content.devices || [];
        },
        error: () => {
          this.dialogRef.close();
          this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
        },
      });
  }
}
