import { Injectable } from '@angular/core';
import { FutDialogService } from '../dialog-service/fut-dialog.service';
import { Observable } from 'rxjs';
import { MigrationDialogComponent } from 'src/app/shared/dialogs/migration-dialog/migration-dialog.component';
import { HttpClient } from '@angular/common/http';
import { UserService } from '../user-service/user.service';
import { environment } from 'src/environments/environment';
import { Resource } from '../interfaces/resource';
import { createCustomHeader } from '../util/http.util';

interface _Migration {
  from_version: string;
  progress: number;
  started_at: number;
  status: 'in_progress' | 'completed' | 'failed';
  to_version: string;
}

export type Migration = Resource<_Migration>;

@Injectable({
  providedIn: 'root',
})
export class MigrationService {
  constructor(
    private dialog: FutDialogService,
    private http: HttpClient,
    private userService: UserService
  ) {}

  public openMigrationDialog(): Observable<boolean | undefined> {
    return this.dialog
      .open<MigrationDialogComponent, null, boolean>(MigrationDialogComponent, {
        dimension: 'md',
        showLoginBackground: true,
        matConf: { disableClose: true, closeOnNavigation: false },
      })
      .afterClosed();
  }

  public startMigration(): Observable<{ migration_uuid: string }> {
    this.userService.clearFuturaUserCache();
    return this.http.post<{
      migration_uuid: string;
    }>(environment.be_url + '/migration/', {});
  }

  public migrationStatus(migration_uuid: string): Observable<Migration> {
    return this.http.get<Migration>(
      environment.be_url + '/migration/',
      createCustomHeader({
        target_resource: migration_uuid,
      })
    );
  }
}
