<div
  #hoverPerimeterTextScrolling
  class="element-wrapper align-items-center"
  [ngClass]="{
    collapsed: collapsed(),
    liveroom: element().routerLink === 'live' && isLive() && !collapsed(),
    'liveroom-compact': element().routerLink === 'live' && isLive() && collapsed(),
    active: isActive()
  }"
  (mouseenter)="startAnimationOnHover()"
  (mouseleave)="stopAnimationOnHover()">
  <div class="icon-sidenav">
    <ng-lottie [options]="animatedLottieOptions()" (animationCreated)="animationCreated($event)"></ng-lottie>
  </div>
  <div
    appTextScrolling
    [hoverPerimeterTextScrolling]="hoverPerimeterTextScrolling"
    [contentText]="element().name + '.title' | transloco"
    class="title-sidenav"></div>
</div>
