import { Injectable } from '@angular/core';
import { loadSpace, SpaceApi, WidgetApi } from '@usersnap/browser';
import { gEnvironment } from '../../../environments/global_environment';
import { User } from '../interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class UsersnapService {
  private usersnapApi?: SpaceApi = undefined;

  public init(user: User): void {
    loadSpace(gEnvironment.usersnap_key)
      .then(api => {
        api.init({
          custom: {
            phone_number: `${user.content.prefix}${user.content.phone}`,
            name: `${user.content.name} ${user.content.surname}`,
          },
          user: {
            userId: user.instance_id,
            email: user.content.email || '',
          },
        });
        this.usersnapApi = api;
      })
      .catch(console.error);
  }

  public openWidget(projectKey: string): void {
    if (!this.usersnapApi) {
      console.error('Usersnap API is not initialized');
      return;
    }
    this.usersnapApi.show(projectKey).then((widgetApi: WidgetApi) => {
      widgetApi.open({});
      widgetApi.on('close', () => {
        if (!this.usersnapApi) {
          return;
        }
        this.usersnapApi.hide(projectKey);
      });
    });
  }
}
