import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavComponent } from './component/sidenav/sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { SidenavElementComponent } from './component/sidenav-element/sidenav-element.component';
import { LottieComponent } from 'ngx-lottie';
import { TranslocoModule } from '@jsverse/transloco';
import { ProfileSectionComponent } from './component/profile-section/profile-section.component';
import { MatMenuModule } from '@angular/material/menu';
import { HelpSectionComponent } from './component/help-section/help-section.component';
import { LogoSectionComponent } from './component/logo-section/logo-section.component';
import { AliceV2Module } from '../alice-v2/alice-v2.module';
import { StreakModule } from '../streak/streak.module';
import { ScrollWithWheelModule } from '../scroll-with-wheel/scroll-with-wheel.module';
import { TextScrollingDirective } from '../text-scrolling/text-scrolling.directive';
import { GhostStateFragmentDirective } from '@futura/futura-ui/ghost';
import { ButtonMenuContentComponent, ButtonMenuElementComponent } from '@futura/futura-ui/button';

@NgModule({
  declarations: [SidenavComponent, SidenavElementComponent, ProfileSectionComponent, HelpSectionComponent, LogoSectionComponent],
  imports: [
    CommonModule,
    MatIconModule,
    RouterModule,
    TranslocoModule,
    MatMenuModule,
    AliceV2Module,
    StreakModule,
    ScrollWithWheelModule,
    LottieComponent,
    TextScrollingDirective,
    GhostStateFragmentDirective,
    ButtonMenuElementComponent,
    ButtonMenuContentComponent,
  ],
  exports: [SidenavComponent],
})
export class SidenavModule {}
