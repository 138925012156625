<app-alice-chat></app-alice-chat>

<button (click)="openGeneralSupport()" hidden id="button-general-support" style="display: none"></button>

<div class="g-container">
  <app-sidenav></app-sidenav>
  <div [ngClass]="{ 'mobile-sidenav-open': hiddenMobileOverflow }" class="page-container">
    <router-outlet #o="outlet"></router-outlet>
    @if (pageBottomSpace) {
      <div class="spacing-bottom"></div>
    }
  </div>
</div>
<app-alice-chat-button (buttonVisibility)="pageBottomSpace = $event"></app-alice-chat-button>
