<div #perimeterTextScrolling class="profile">
  <div [matMenuTriggerFor]="profileMenu" [ngClass]="{ collapsed: collapsed(), active: activeElement() === routerLink }" class="wrapper-icon">
    <div class="profile-sidenav">
      <img *futGhostStateFragment="stateUser(); textMinChar: 2; textMaxChar: 2" [src]="userImage()" (error)="missingImg()" alt="profile picture" />
    </div>
    <ng-container *futGhostStateFragment="stateUser(); textMinChar: 10; textMaxChar: 15">
      @if (user()) {
        <div appTextScrolling [hoverPerimeterTextScrolling]="perimeterTextScrolling" class="title-sidenav">{{ user()?.content?.nickname }}</div>
      }
    </ng-container>
  </div>

  <div class="d-flex justify-content-center flex-wrap align-content-center">
    <div (click)="openStreak()" [ngClass]="{ collapsed: collapsed() }" class="streak">
      <div class="streak-image">
        <img *futGhostStateFragment="stateStrike(); textMinChar: 2; textMaxChar: 2" [src]="streakIcon()" alt="Streak" />
      </div>
      <ng-container *futGhostStateFragment="stateStrike(); textMinChar: 2; textMaxChar: 4">
        @if (currentStreakCount()) {
          <span class="current-streak">{{ currentStreakCount() }}</span>
        } @else {
          <span class="current-streak">--</span>
        }
      </ng-container>
    </div>
  </div>
</div>

<mat-menu #profileMenu="matMenu">
  <ng-template matMenuContent>
    <fut-button-menu-content>
      @if (showProfileRoute()) {
        <fut-button-menu-element
          [routerLink]="routerLink"
          [iconInfo]="{ icon: 'account_circle', fontSet: 'material-symbols-outlined' }"
          elementLabel="sidenav.edit_profile">
        </fut-button-menu-element>
      }

      <fut-button-menu-element
        (click)="openDeviceList()"
        elementLabel="sidenav.device_list"
        [iconInfo]="{ icon: 'laptop_windows', fontSet: 'material-symbols-outlined' }">
      </fut-button-menu-element>

      <fut-button-menu-element [routerLink]="'/logout'" elementLabel="sidenav.logout" [iconInfo]="{ icon: 'logout', fontSet: 'material-symbols-outlined' }">
      </fut-button-menu-element>
    </fut-button-menu-content>
  </ng-template>
</mat-menu>
