@if (!start_animation) {
  <swiper
    (swiper)="onSwiper($event)"
    [spaceBetween]="0"
    [allowTouchMove]="false"
    [keyboard]="false"
    [direction]="'horizontal'"
    [autoHeight]="true"
    [pagination]="false"
    id="swiper-required-user-info-dialog">
    <ng-template swiperSlide>
      <div class="d-flex justify-content-start align-items-center mt-1" mat-dialog-title>
        <div class="title-with-emoji flex-column">
          <img src="./assets/emoji/waving_hand.svg" class="mb-2" />
          <span class="fut-h1 mb-2 text-center">{{ 'dialogs.user_info.title' | transloco }}</span>
        </div>
      </div>
      <div class="pb-2 scrollbar-mac info-container">
        <p class="w-100 text-center mb-4">{{ 'dialogs.user_info.sub_title' | transloco }}</p>
        <div class="row py-2">
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'profile.edit.name' | transloco }}</mat-label>
              <input
                [formControl]="form_name"
                type="name"
                [name]="'profile.edit.name' | transloco"
                matInput
                [(ngModel)]="data.user.content.name"
                (keyup)="changeUsername()" />
              <mat-error>{{ 'utils.errors.required_field' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'profile.edit.surname' | transloco }}</mat-label>
              <input
                [formControl]="form_surname"
                type="surname"
                [name]="'profile.edit.surname' | transloco"
                matInput
                [(ngModel)]="data.user.content.surname"
                (keyup)="changeUsername()" />
              <mat-error>{{ 'utils.errors.required_field' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12" style="padding-top: 24px">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'profile.edit.username' | transloco }}</mat-label>
              <input
                [formControl]="form_username"
                type="username"
                [name]="'profile.edit.username' | transloco"
                matInput
                [(ngModel)]="data.user.content.nickname"
                (change)="username_focused = true" />
              <mat-error>{{ 'utils.errors.required_field' | transloco }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-12">
            <div class="d-flex flex-column w-100 h-100 mt-3">
              <div class="w-100 text-center">
                <h3 class="fut-h3">{{ 'profile.edit.birthday' | transloco }}</h3>
              </div>
              <div class="w-100">
                <div class="futura-data-picker">
                  <input type="date" [formControl]="form_birth_date" [name]="'profile.edit.birthday' | transloco" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    @if (false) {
      <ng-template swiperSlide>
        <div class="d-flex justify-content-start align-items-center mt-1" mat-dialog-title>
          <div class="title-with-emoji flex-column">
            <img src="./assets/emoji/clipboard.svg" class="mb-2" />
            <span class="fut-h1 mb-2 text-center">{{ 'dialogs.real_test_info.title' | transloco }}</span>
          </div>
        </div>
        <div class="pb-2 scrollbar-mac">
          <p class="w-100 text-center mb-4">{{ 'dialogs.real_test_info.sub_title' | transloco }}</p>
          <div class="row py-2">
            <div class="col-12 mb-3">
              <div class="row emoji-cards">
                <div class="col-12 mb-2">
                  <h3 class="fut-h3">{{ 'dialogs.user_info.second_slide.expected_score.title' | transloco }}</h3>
                </div>
                <div class="col-4">
                  <div
                    class="emoji-card"
                    (click)="expected_score = score_steps[0].toString()"
                    [ngClass]="{ active: expected_score == score_steps[0].toString() }">
                    <img class="emoji" src="./assets/emoji/wink.svg" class="mb-2" />
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.low.title' | transloco }}</span>
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.low.subtitle' | transloco }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="emoji-card"
                    (click)="expected_score = score_steps[1].toString()"
                    [ngClass]="{ active: expected_score == score_steps[1].toString() }">
                    <img class="emoji" src="./assets/emoji/nerd_face.svg" class="mb-2" />
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.medium.title' | transloco }}</span>
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.medium.subtitle' | transloco }}</span>
                  </div>
                </div>
                <div class="col-4">
                  <div
                    class="emoji-card"
                    (click)="expected_score = score_steps[2].toString()"
                    [ngClass]="{ active: expected_score == score_steps[2].toString() }">
                    <img class="emoji" src="./assets/emoji/grinning_face.svg" class="mb-2" />
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.high.title' | transloco }}</span>
                    <span class="font-weight-bold">{{ 'dialogs.user_info.second_slide.expected_score.expected_scores.high.subtitle' | transloco }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="d-flex flex-column">
                <h3 class="text-center fut-h3 mb-3 text-md-left">{{ 'dialogs.user_info.second_slide.test_date.title' | transloco }}</h3>
                <div class="fut-segmented-buttons">
                  <button
                    class="fut-segmented-button"
                    (click)="setDateType(UserTestDateType.Soon)"
                    [ngClass]="{ active: UserTestDateType.Soon == test_date_type }">
                    {{ 'dialogs.user_info.second_slide.test_date.choice.soon' | transloco }}
                  </button>
                  <button
                    class="fut-segmented-button"
                    (click)="setDateType(UserTestDateType.Fixed)"
                    [ngClass]="{ active: UserTestDateType.Fixed == test_date_type }">
                    {{ 'dialogs.user_info.second_slide.test_date.choice.fixed' | transloco }}
                  </button>
                  <button
                    class="fut-segmented-button"
                    (click)="setDateType(UserTestDateType.NotKnow)"
                    [ngClass]="{ active: UserTestDateType.NotKnow == test_date_type }">
                    {{ 'dialogs.user_info.second_slide.test_date.choice.not_know' | transloco }}
                  </button>
                </div>
                <!-- <fut-segmented-buttons [(value)]="test_date_type" [enumerator]="UserTestDateType" [displayValues]="['Al più presto', 'Data esatta', 'Non so']"></fut-segmented-buttons> -->
              </div>
            </div>
            @if (test_date_type == UserTestDateType.Fixed) {
              <div class="col-12 mt-4 mb-2">
                <div class="row justify-content-center align-items-center">
                  <div class="col-8 col-md-6">
                    <mat-form-field appearance="outline">
                      <mat-label>{{ 'dialogs.real_test_info.form.test_date' | transloco }}</mat-label>
                      <input
                        matInput
                        [matDatepicker]="picker"
                        [formControl]="form_test_date"
                        [matDatepickerFilter]="filterPastDate"
                        [placeholder]="dateFormat"
                        [(ngModel)]="test_date" />
                      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                      <mat-datepicker [touchUi]="is_mobile" #picker></mat-datepicker>
                      <mat-error>{{ 'dialogs.user_info.second_slide.invalid_test_date' | transloco }}</mat-error>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            }
            <!-- <div class="col-12">
            <div class="row">
              <div class="col-5 col-md-6">
                <div class="d-flex justify-content-start align-items-center h-100 w-100">
                  <span>Quando sarà il test?</span>
                </div>
              </div>
              <div class="col-7 col-md-6">
                <div class="d-flex justify-content-end align-items-center w-100 h-100">
                  <mat-form-field appearance="outline">
                    <mat-label>{{'dialogs.real_test_info.form.test_date' | transloco}}</mat-label>
                    <input matInput [matDatepicker]="picker" [matDatepickerFilter]="filterPastDate" [placeholder]="dateFormat" [(ngModel)]="test_date">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker [touchUi]="is_mobile" #picker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div> -->
          </div>
          <div class="row align-items-center my-4">
            <div class="col-12 mb-2 mb-md-0">
              <div class="d-flex justify-content-start align-items-center">
                <h3 class="text-center fut-h3 text-md-left">{{ 'dialogs.real_test_info.form.weekly_hour' | transloco }}</h3>
              </div>
            </div>
            <div class="col-12 mt-2">
              <div class="w-100 d-flex justify-content-center align-items-center">
                <button class="fut-btn fut-btn-outline fut-btn-pill" (click)="changeDailyHours(-1)" [disabled]="daily_hours == 1">
                  <mat-icon fontSet="material-symbols-outlined">remove</mat-icon>
                </button>
                <span class="font-weight-bold text-center" style="font-size: 1.4em; min-width: 2.5em">{{ daily_hours }}</span>
                <button class="fut-btn fut-btn-outline fut-btn-pill" (click)="changeDailyHours(+1)" [disabled]="daily_hours >= 16">
                  <mat-icon fontSet="material-symbols-outlined">add</mat-icon>
                </button>
              </div>
            </div>
          </div>
          @if (daily_hours >= 14) {
            <div class="w-100 d-flex justify-content-center align-items-center flex-column flex-md-row error-container mb-3">
              <span class="text-center order-2 order-md-1" style="width: max-content !important"></span
              >{{ 'dialogs.user_info.second_slide.hour_error' | transloco }}
              <img width="20" src="./assets/emoji/sleeping_face.svg" class="ml-0 ml-md-1 order-1 order-md-2" />
            </div>
          }
        </div>
      </ng-template>
    }
    @if (false) {
      <ng-template swiperSlide>
        <div class="d-flex justify-content-start align-items-center mt-1" mat-dialog-title>
          <div class="title-with-emoji flex-column">
            <img src="./assets/emoji/graduation_cap.svg" class="mb-2" />
            <span class="fut-h1 mb-2 text-center">{{ 'dialogs.auto_evaluation.title' | transloco }}</span>
          </div>
        </div>
        <div class="mb-3 scrollbar-mac">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <p class="w-100 text-center mb-4" style="max-width: 24em">{{ 'dialogs.auto_evaluation.sub_title' | transloco }}</p>
          </div>
          <div class="d-flex flex-column overflow-avoid">
            @if (show_slider) {
              <div class="row">
                <div class="col-5"></div>
                <div class="col-7">
                  <div class="w-100 d-flex justify-content-between align-items-center">
                    <img width="25" [src]="'./assets/emoji/' + self_evaulation_emoji[0] + '.svg'" />
                    <img width="25" [src]="'./assets/emoji/' + self_evaulation_emoji[self_evaulation_emoji.length - 1] + '.svg'" />
                  </div>
                </div>
              </div>
            }
            @for (category of categories; track category) {
              <div class="row mb-2 mb-md-1 py-2 w-100">
                <div class="col-4 col-md-7">
                  <div class="w-100 h-100 d-flex justify-content-start align-items-center">
                    <h3 class="m-0">{{ displayCat(category, true) }}</h3>
                  </div>
                </div>
                <div class="col-8 col-md-5">
                  @if (!show_slider) {
                    <div class="d-flex justify-content-between align-items-center h-100 emoji-list">
                      @for (emoji of self_evaulation_emoji; track emoji) {
                        <img
                          [src]="'./assets/emoji/' + emoji + '.svg'"
                          [ngClass]="{ active: self_evaulation_category[category] && self_evaulation_category[category] == getEmojiValue(emoji) }"
                          (click)="setSelfEvaulation(category, getEmojiValue(emoji))" />
                      }
                    </div>
                  }
                  @if (show_slider) {
                    <div class="w-100">
                      <mat-slider showTickMarks class="w-100" [max]="5" [min]="1" [step]="1" [(ngModel)]="self_evaulation_category[category]"
                        ><input matSliderThumb />
                      </mat-slider>
                    </div>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </ng-template>
    }
  </swiper>
}

@if (!start_animation) {
  <div class="d-flex justify-content-end mb-1" style="padding-left: 0.1em; padding-right: 0.1em" mat-dialog-actions>
    <!-- <button class="fut-btn fut-btn-outline mr-3" [disabled]="!swiper || swiper.activeIndex == 0" (click)="prev()">{{'dialogs.user_info.back' | transloco}}</button> -->
    <button class="fut-btn fut-btn-accent" (click)="next()">
      <!-- {{ (swiper && swiper.activeIndex == 2 ? 'dialogs.user_info.confirm' : 'dialogs.user_info.next') | transloco}} -->
      {{ 'dialogs.user_info.confirm' | transloco }}
    </button>
  </div>
}

@if (start_animation && loading) {
  <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <div class="lottie-container">
      <ng-lottie [options]="{ path: './assets/lotties/doc_analisys.json' }" style="width: 30em"></ng-lottie>
    </div>
    <h2>{{ 'dialogs.user_info.loading.animation_wait.title' | transloco }}</h2>
    <div style="max-width: 17em" class="text-center">{{ 'dialogs.user_info.loading.animation_wait.subtitle' | transloco }}</div>
  </div>
}

<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
<app-confetti [showConfetti]="showConfetti.asObservable()"></app-confetti>
@if (start_animation && !loading) {
  <div class="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
    <div class="lottie-container">
      <ng-lottie [options]="{ path: './assets/lotties/success.json', loop: false }" style="width: 30em"></ng-lottie>
    </div>
    <h1 class="fut-h1 mt-4">{{ 'dialogs.user_info.loading.animation_success.title' | transloco }}</h1>
    <h3 class="fut-h3">{{ 'dialogs.user_info.loading.animation_success.subtitle' | transloco: { hour: getSuggestDailyHours() } }}</h3>
    <span class="text-center mb-3" style="width: 80%">{{ 'dialogs.user_info.loading.animation_success.content' | transloco }}</span>
    <!-- <div style="max-width: 17em" class="text-center">{{'dialogs.user_info.loading.animation_success.subtitle' | transloco:{'hour': getFuturaSuggestDailyHours()} }}</div> -->
  </div>
}
