import { inject, Injectable } from '@angular/core';
import { TranslocoLoader } from '@jsverse/transloco';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { environment } from '../environments/environment';
import { catchError, map, tap } from 'rxjs/operators';

const EXPIRE_TIME = 12 * 60 * 60 * 1000;
type Labels = { [label: string]: string };

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    const assetsRequest = this.http.get<Labels>(`/assets/i18n/${lang}.json`);

    const langSaved = localStorage.getItem(`language-saved-${lang}`);
    const langSavedDate = localStorage.getItem(`language-saved-date-${lang}`);

    // If lang is saved and not expired use it instead of http request and save a request
    let httpRequest: Observable<Labels>;
    if (langSaved && langSavedDate && Date.now() - +langSavedDate < EXPIRE_TIME) {
      httpRequest = of(JSON.parse(langSaved) as unknown as Labels);
    } else {
      httpRequest = this.http.get<Labels>(`${environment.be_url}/config/${environment.platform}/translations/${lang}`).pipe(
        tap(res => {
          // Save lang to local storage
          localStorage.setItem(`language-saved-${lang}`, JSON.stringify(res));
          localStorage.setItem(`language-saved-date-${lang}`, Date.now().toString());
        }),
        catchError(() => of({}))
      );
    }

    // ? If you want to force http request comment the previous lines and uncomment the following
    // const httpRequest = this.http.get(`${environment.be_url}/config/${environment.platform}/translations/${lang}`).pipe(
    //   catchError(() => of({}))
    // );

    return forkJoin([assetsRequest, httpRequest]).pipe(
      map(([assetsTranslations, httpTranslations]) => {
        const translations: Labels = {};

        Object.keys(assetsTranslations).forEach(key => {
          translations[key] = assetsTranslations[key];
        });

        Object.keys(httpTranslations).forEach(key => {
          if (httpTranslations[key]) translations[key] = httpTranslations[key];
        });

        return translations;
        // return { ...assetsTranslations, ...httpTranslations };
      })
    );
  }
}
