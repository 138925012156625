import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../core/products-service/products.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/core/user-service/user.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-coupon-route',
  template: '',
})
export class CouponRouteComponent implements OnInit {
  coupon_id: string = this.route.snapshot.paramMap.get('coupon') as string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private toast: ToastrService,
    private uiService: UiService,
    private productService: ProductsService
  ) {}

  ngOnInit() {
    this.uiService.showSpinner = true;
    this.userService.getAuthToken().subscribe({
      next: () => {
        this.productService
          .activateCoupon(this.coupon_id)
          .pipe(finalize(() => (this.uiService.showSpinner = false)))
          .subscribe({
            next: coupon => {
              // this.dialog.open(InfoDialogComponent, {data: {content: `Coupon ${coupon.content.name} attivato con successo!`}});
              this.toast.success(`Coupon ${coupon.content.name} attivato con successo!`, '', {
                progressBar: true,
                closeButton: true,
                positionClass: 'toast-bottom-right',
                timeOut: 3000,
              });
              localStorage.setItem('coupon', this.coupon_id);
              this.router.navigate(['products']).catch(console.error);
            },
            error: () => this.router.navigate(['products']).catch(console.error),
          });
      },
      error: () => {
        this.toast.warning(`Per attivare il coupon devi prima effettuare il login`, '', {
          progressBar: true,
          closeButton: true,
          positionClass: 'toast-bottom-right',
          timeOut: 3000,
        });
        this.router.navigate(['login']).catch(console.error);
        localStorage.setItem('try_coupon', this.coupon_id);
        this.uiService.showSpinner = false;
      },
    });
  }
}
