import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { GoogleVerificationComponent } from './view/google-verification/google-verification.component';

const routes: Routes = [
  {
    path: '500',
    loadChildren: () => import('./view/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
  },
  {
    path: 'p',
    loadChildren: () => import('./view/external-product/external-product.module').then(m => m.ExternalProductModule),
  },
  { path: 'google6c72d406b711af94.html', component: GoogleVerificationComponent },
];

if (environment?.production === false) {
  routes.push({
    path: 'demo',
    loadChildren: () => import('./view/demo/demo.module').then(m => m.DemoModule),
  });
}

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
