import { TranslocoService } from '@jsverse/transloco';
import { MatDialog } from '@angular/material/dialog';
import { SuccessDialogComponent, SuccessDialogData } from '../../../shared/dialogs/success-dialog/success-dialog.component';
import { UtilsService } from 'src/app/core/utils-service/utils.service';
import { ProductsService } from '../../../core/products-service/products.service';
import { UiService } from 'src/app/core/ui-service/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-scalapay',
  templateUrl: './scalapay.component.html',
  styleUrls: ['./scalapay.component.scss'],
})
export class ScalapayComponent implements OnInit {
  orderToken = this.route.snapshot.queryParams.orderToken;
  status = this.route.snapshot.queryParams.status;
  stripePaid = this.route.snapshot.queryParams.stripePaid == 'true';

  constructor(
    private route: ActivatedRoute,
    private uiService: UiService,
    private productsService: ProductsService,
    private utils: UtilsService,
    private dialog: MatDialog,
    private router: Router,
    private translate: TranslocoService
  ) {}

  ngOnInit(): void {
    this.uiService.showSpinner = true;
    if (this.stripePaid) {
      this.uiService.showSpinner = false;
      this.successDialog();
      return;
    }
    if (this.status == 'SUCCESS') {
      console.log('here');
      this.productsService
        .captureScalapayOrder(this.orderToken)
        .pipe(finalize(() => (this.uiService.showSpinner = false)))
        .subscribe({
          next: order => {
            // console.log({order});
            if (order.url) {
              this.uiService.showSpinner = true;
              location.href = order.url;
            } else this.successDialog();
          },
          error: () => {
            this.router.navigate(['500']);
            this.uiService.errorToast({ title: this.translate.translate('utils.errors.general') });
          },
        });
      console.log(this.orderToken, this.status);
    } else {
      const last_href = localStorage.getItem('futura_last_page');
      if (last_href) {
        window.open(last_href, '_self');
        localStorage.removeItem('futura_last_page');
      } else this.utils.gotoHomePage().catch(console.error);
    }
  }

  successDialog() {
    this.utils.sendEvent('pv_payment_final_dialog');
    this.dialog
      .open(SuccessDialogComponent, {
        data: {
          title: "Grazie per l'acquisto!",
          message: 'Il pagamento è stato autorizzato con successo.',
          // submessage: `${user.content.prefix}${user.content.phone}`,
          show_confetti: true,
          auto_close: true,
          // button: 'Vai a Futura'
        } as SuccessDialogData,
        width: '25vw',
        backdropClass: 'dialog-mobile-no-padding',
      })
      .afterClosed()
      .subscribe(() => {
        this.utils.gotoHomePage();
      });
  }
}
